<template>
  <div class="pageContainer targetPage flexCloumn">
    <el-row class="searchBar flexBetween" style="flex-wrap: wrap;width:100%">
      <div class="flexStart flex_1" style="flex-wrap:wrap;">
        <el-input placeholder="登录名" v-model="searchForm.login_name" size="small" class="input-with-select"
          style="width:150px;margin: 0 10px 10px 0;" clearable></el-input>
        <el-input placeholder="企业名称" v-model="searchForm.firm_name" size="small" class="input-with-select"
          style="width:150px;margin: 0 10px 10px 0;" clearable></el-input>
        <el-input placeholder="组织代码" v-model="searchForm.firm_num" size="small" class="input-with-select"
          style="width:150px;margin: 0 10px 10px 0;" clearable></el-input>
        <el-input placeholder="联系电话" v-model="searchForm.tel" size="small" class="input-with-select"
          style="width:150px;margin: 0 10px 10px 0;" clearable></el-input>
        <el-select v-model="searchForm.info" clearable placeholder="企业性质" size="small"
          style="width:130px;margin: 0 10px 10px 0;">
          <el-option label="全部性质" value></el-option>
          <el-option :label="item.param_desc" v-for="(item, idx) in qyxzArr" :key="idx" :value="(idx)"></el-option>
        </el-select>
        <el-select v-model="searchForm.is_cooperation" clearable placeholder="是否合作企业" size="small"
          style="width:130px;margin: 0 10px 10px 0;">
          <el-option label="是否合作企业" value></el-option>
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
        <el-select v-model="searchForm.email_status" clearable placeholder="是否激活" size="small"
          style="width:130px;margin: 0 10px 10px 0;">
          <el-option label="是否激活" value></el-option>
          <el-option label="否" :value="0"></el-option>
          <el-option label="是" :value="1"></el-option>
        </el-select>
        <el-select v-model="searchForm.check_status" clearable placeholder="审核状态" size="small"
          style="width:130px;margin: 0 10px 10px 0;">
          <el-option label="审核状态" value></el-option>
          <el-option label="待审核" :value="0"></el-option>
          <el-option label="审核通过" :value="1"></el-option>
          <el-option label="审核不通过" :value="2"></el-option>
        </el-select>
        <el-button icon="el-icon-search" style="margin: 0 10px 10px 0;" size="small" type="primary"
          @click="() => { page.current_page = 1; getList() }">搜索
        </el-button>
      </div>
      <div class="flexStart"
        style="color: #3378fa;font-size: 14px;font-weight: 700;margin: 0 5px 0 30px;cursor: pointer;">

        <el-button size="small" icon="el-icon-download" @click="exportExcelDate" type="primary">导出数据</el-button>
        <el-button v-if="$store.state.config.sync_data" size="small" icon="el-icon-refresh" @click="tongbuJava"
          type="primary">迁移老版本
        </el-button>
      </div>

    </el-row>
    <div class="dividerBar"></div>

    <el-table v-tableFit :data="tableData" row-key="id" height="100%" size="small" stripe border @sort-change="sortChange"
      style="width:fit-content;width:100%;">
      <el-table-column fixed="left" type="index" label="序号" width="65" align="center" />
      <el-table-column fixed="left" label="审核状态" prop="check_status" width="100px" align="center">
        <template slot-scope="scope">
          <el-tag size="mini" v-if="scope.row.check_status == 0 || !scope.row.check_status" type="primary">待审核</el-tag>
          <el-tag size="mini" v-if="scope.row.check_status == 1" type="success">审核通过</el-tag>
          <el-tag size="mini" v-if="scope.row.check_status == 2" type="danger">审核不通过</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="left" label="企业名称" prop="firm_name" show-overflow-tooltip width="180" align="center" />
      <el-table-column label="组织代码" prop="firm_num" show-overflow-tooltip width="150" />
      <el-table-column label="企业联系人" prop="firm_principal" width="150px" align="center" />
      <el-table-column label="联系电话" prop="tel" width="135" align="center" />
      <el-table-column v-if="$store.state.pmid == 220" label="职务" prop="job" width="120" align="center" />
      <el-table-column label="企业性质" prop="info" width="135" align="center">
        <template slot-scope="scope">{{ qyxz[scope.row.info] || scope.row.enter_type || "-" }}</template>
      </el-table-column>
      <el-table-column label="地区" prop="area_id" width="200pd" align="center">
        <template slot-scope="scope">
          {{ scope.row.area_name || scope.row.area_name_new || CITY_DIC[scope.row.area_id] || "-" }}
        </template>

      </el-table-column>
      <el-table-column label="企业地址" prop="address" show-overflow-tooltip width="200px"></el-table-column>
      <el-table-column label="是否合作企业" prop="is_cooperation" width="150px" align="center">
        <template slot-scope="scope">{{ scope.row.is_cooperation == 1 ? "是" : "否" }}</template>
      </el-table-column>
      <el-table-column label="邮箱是否激活" prop="email_status" width="150px" align="center">
        <template slot-scope="scope">{{ scope.row.email_status == 1 ? "是" : "否" }}</template>
      </el-table-column>
      <el-table-column label="所属行业" prop="industry_name" show-overflow-tooltip min-width="135" align="center" />
      <el-table-column label="校内联系人" prop="xnlxr" min-width="135" align="center" />
      <el-table-column v-if="$store.state.pmid != 220" sortable="create_date" label="企业提交时间" prop="create_date" min-width="135" align="center">
        <template slot-scope="scope">
          {{ scope.row.create_date | handleDate }}
        </template>
      </el-table-column>
      <el-table-column v-else sortable="create_date" label="注册日期" prop="create_date" min-width="135" align="center" />
      <el-table-column label="登录名" prop="login_name" width="180" align="center" />

      <el-table-column fixed="right" label="操作" width="220" align="center">
        <template slot-scope="scope">
          <el-tag class="pointer" @click="enterprisePwd(scope.row)" type="warning" size="mini">重置密码</el-tag>
          <el-tag class="pointer" @click="handleModify(scope.row)" type="primary" size="mini"
            style="margin-left:10px;">{{ (scope.row.check_status == 0 || !scope.row.check_status) ? '审核' : '查看' }}
          </el-tag>
          <!--          <el-tag class="pointer" v-if="scope.row.check_status === 0" @click="handleModify(scope.row)" type="success"
                      size="mini" style="margin-left:10px;">审核</el-tag>-->
          <el-tag v-if="isAdmin" class="pointer" @click="handleDelete(scope.row)" type="danger" size="mini"
            style="margin-left:10px;">删除
          </el-tag>
          <div v-if="scope.row.check_status == 1 && scope.row.job_count >= 0 && scope.row.pmid == 220">
            <!-- 只有常州旅商有招聘启事 -->
            <el-tag class="pointer" @click="goZhaopinqishi(scope.row.id)" type="success" size="mini"
              style="margin-left:10px;">招聘启事
            </el-tag>
            <el-tag class="pointer" @click="exportWord(scope.row.id)" type="success" size="mini"
              style="margin-left:10px;">导出招聘启事word
            </el-tag>
          </div>

        </template>
      </el-table-column>
    </el-table>
    <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="page.current_page"
      :page-size="page.per_page" :total="page.count" layout="total, sizes,prev, pager, next"
      :page-sizes="[10, 20, 50, 100]" style="margin-top: 10px;">
    </el-pagination>

    <el-dialog :visible.sync="dialogForm" :close-on-click-modal="false" :destroy-on-close="false"
      custom-class="cus_dialog" width="1000px" @open="dragDialog('dialogDetail')" ref="dialogDetail">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">入驻企业详情</span>
      </div>

      <div class="dialog_right">
        <div class="flexStart"
          style="flex-wrap: wrap;width: 100%;margin-top: 10px;border-right: 1px solid #d9e4f6;border-bottom: 1px solid #d9e4f6;">
          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart" style="min-height: 40px;height: unset;">企业名称</div>
            <div class="cell flexStart flex_1" style="height: unset;min-height: 40px;">
              {{ formData.firm_name }}
              <el-tag class="ml10" size="mini" v-if="formData.check_status == 0 || !formData.check_status"
                type="peimary">待审核</el-tag>
              <el-tag class="ml10" size="mini" v-if="formData.check_status == 1" type="success">审核通过</el-tag>
              <el-tag class="ml10" size="mini" v-if="formData.check_status == 2" type="danger">审核不通过</el-tag>

              <el-tag size="mini" v-if="formData.check_status == 2" type="danger" style="margin-left:10px">
                {{ formData.remarks }}
              </el-tag>
            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">企业性质</div>
            <div class="cell flexStart flex_1">{{ qyxz[formData.info] || (formData.tyc && formData.tyc.enter_type) ||
          "-"
              }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">所属行业</div>
            <div class="cell flexStart flex_1">{{ formData.industry_name }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart">企业地址</div>
            <div class="cell flexStart flex_1">{{ formData.address }}</div>
          </div>

          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">企业联系人</div>
            <div class="cell flexStart flex_1">{{ formData.firm_principal }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">联系电话</div>
            <div class="cell flexStart flex_1">{{ formData.tel }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">职务</div>
            <div class="cell flexStart flex_1">{{ formData.job || '-' }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">企业类型</div>
            <div class="cell flexStart flex_1">
              <span v-if="formData.is_cooperation == 1" style="color:green">合作企业</span>
              <span v-if="formData.is_cooperation == 0" style="color:red">非合作企业</span>
            </div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">企业邮箱</div>
            <div class="cell flexStart flex_1">{{ formData.email || '-' }}</div>
          </div>
          <div class="lineRow flexStretch" style="width:50%;">
            <div class="cell flexStart">邮箱状态</div>
            <div class="cell flexStart flex_1">
              <span :style="{ color: formData.email_status == 1 ? 'green' : 'orange' }">
                {{ formData.email_status == 1 ? '已激活' : '未激活' }}</span>
            </div>
          </div>
          <div class="lineRow flexStretch" style="width:100%;">
            <div class="cell flexStart">注册时间</div>
            <div class="cell flexStart flex_1">{{ formData.create_date || '-' }}</div>
          </div>



        </div>
      </div>
      <el-form :model="formData" label-width="100px">
        <el-row v-if="false">
          <el-col :span="24">
            <el-form-item label="企业名称:">{{ formData.firm_name }}
              <el-tag size="mini" v-if="formData.check_status == 0 || !formData.check_status"
                type="peimary">待审核</el-tag>
              <el-tag size="mini" v-if="formData.check_status == 1" type="success">审核通过</el-tag>
              <el-tag size="mini" v-if="formData.check_status == 2" type="danger">审核不通过</el-tag>

              <el-tag size="mini" v-if="formData.check_status == 2" type="danger" style="margin-left:10px">
                {{ formData.remarks }}
              </el-tag>

            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="false">
          <el-col :span="8">
            <el-form-item label="企业性质:">{{ qyxz[formData.info] || (formData.tyc && formData.tyc.enter_type) || "-"
              }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属行业:"> {{ formData.industry_name }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业地址:"> {{ formData.address }}</el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="false">
          <el-col :span="6">
            <el-form-item label="企业联系人:"> {{ formData.firm_principal }}</el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="联系电话:"> {{ formData.tel }}</el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="职务:"> {{ formData.job || '-' }}</el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="企业类型:">
              <span v-if="formData.is_cooperation == 1" style="color:green">合作企业</span>
              <span v-if="formData.is_cooperation == 0" style="color:red">非合作企业</span>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="false">
          <el-col :span="8">
            <el-form-item label="企业邮箱:"> {{ formData.email }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮箱状态:">
              <span :style="{ color: formData.email_status == 1 ? 'green' : 'orange' }">{{
          formData.email_status ==
            1 ? '已激活' : '未激活'
        }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="注册时间:"> {{ formData.create_date }}</el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="formData.check_status == 0 || !formData.check_status">
          <el-col :span="18" style="text-align: right">
            <el-input v-model.trim="spword" style="width: 100%" size="small" placeholder="审核拒绝时请填写拒绝原因"></el-input>
          </el-col>
          <el-col :span="5" style="text-align: left;padding-left: 10px;">
            <el-button size="small" type="warning" @click="submitForm(2)">审核拒绝</el-button>
            <el-button size="small" type="primary" @click="submitForm(1)">审核通过</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-tabs type="border-card" style="margin-top: 10px">
            <el-tab-pane label="企业详情">
              <el-row class="flexCenter">
                <el-col :span="12">
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="企业名称:"> {{ formData.firm_name }}</el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="组织代码:"> {{ formData.firm_num }}</el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="企业法人:"> {{ formData.legal_person ? formData.legal_person :
          (formData.tyc && formData.tyc.tyc_json ? formData.tyc.tyc_json.legalPersonName : "-")
                        }}</el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="企业邮编:"> {{ formData.enter_postcode || "-" }}</el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="成立日期:"> {{ formData.establish_date ? formData.establish_date :
          (formData.tyc && formData.tyc.tyc_json && formData.tyc.tyc_json.estiblishTime ?
            $moment(formData.tyc.tyc_json.estiblishTime).format("YYYY-MM-DD") : "-") }}</el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="注册资本:"> {{ formData.register_capital ? `${formData.register_capital}万元` :
          (formData.tyc && formData.tyc.tyc_json ? formData.tyc.tyc_json.regCapital : "-")
                        }}</el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="企业规模:"> {{ unitSize[formData.unit_size] || (formData.tyc &&
          formData.tyc.guimo ? formData.tyc.guimo : "-") }}</el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="所在地区:">
                        {{ formData.area_name || (formData.tyc && formData.tyc.tyc_json ? formData.tyc.tyc_json.city :
          '') }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="企业地址:"> {{ formData.address }}</el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="企业性质:"> {{
          qyxz[formData.info] || (formData.tyc && formData.tyc.tyc_json ?
            formData.tyc.tyc_json.companyOrgType : '')
        }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="所属行业:"> {{ formData.industry_name }}</el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="12" class="flexEnd">
                  <div style="width:80%">
                    <el-image :src="formData.license_imge ? formData.license_imge : src"
                      :preview-src-list="[formData.license_imge]"></el-image>
                  </div>

                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="企业简介">: {{ formData.enter_desc }}</el-form-item>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="发布岗位">
              <el-table v-tableFit :data="formData.jobList" row-key="id" border stripe>
                <el-table-column type="index" label="序号" width="55px" align="center" />
                <el-table-column label="职位名称" prop="job_name" show-overflow-tooltip width="135" align="center" />
                <el-table-column label="职位类型" prop="job_type_name" show-overflow-tooltip width="120" align="center" />
                <el-table-column label="招聘人数" prop="need_num" width="120" align="center" />
                <el-table-column label="工作城市" prop="cityName" show-overflow-tooltip width="120" align="center">
                  <template slot-scope="scope">
                    {{ scope.row.area_name_new || CITY_DIC[scope.row.city] || '-' }}
                  </template>
                </el-table-column>
                <el-table-column label="工作地址" prop="word_addr" show-overflow-tooltip width="200" align="center" />
                <el-table-column label="经验要求" prop="experience" show-overflow-tooltip width="100px" align="center">
                  <template slot-scope="scope">{{ gzjy[scope.row.experience] || '-' }}</template>
                </el-table-column>
                <el-table-column label="学历要求" prop="eduaction" show-overflow-tooltip width="100px" align="center">
                  <template slot-scope="scope">{{ xlyq[scope.row.eduaction] || '-' }}</template>
                </el-table-column>
                <el-table-column label="薪资待遇" prop="pay" show-overflow-tooltip width="160" align="center">
                  <template slot-scope="scope">{{ xzfw[scope.row.pay] || '-' }}</template>
                </el-table-column>
                <el-table-column label="审核状态" prop="check_status" width="100px" align="center">
                  <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.check_status == 0 || !scope.row.check_status"
                      type="primary">审核中</el-tag>
                    <el-tag size="mini" v-if="scope.row.check_status == 1" type="success">审核通过</el-tag>
                    <el-tag size="mini" v-if="scope.row.check_status == 2" type="danger">审核不通过</el-tag>
                    <el-tag size="mini" v-if="scope.row.check_status == 3" type="info">已关闭</el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="审核记录" width="120" align="center">
                  <template slot-scope="scope">
                    <el-tag class="pointer" @click="() => {
          checkedInfo = scope.row
          showCheckedInfo = true
        }" type="primary" size="small">审核记录
                    </el-tag>
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="审核记录">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="审核状态:">
                    <el-tag size="mini" v-if="formData.check_status == 0 || !formData.check_status"
                      type="primary">待审核</el-tag>
                    <el-tag size="mini" v-if="formData.check_status == 1" type="success">审核通过</el-tag>
                    <el-tag size="mini" v-if="formData.check_status == 2" type="danger">审核不通过</el-tag>
                  </el-form-item>
                </el-col>
                <el-col :span="24" v-if="formData.check_status">
                  <el-form-item label="评语">: {{ formData.remarks || '-' }}</el-form-item>
                </el-col>
                <el-col :span="24" v-if="formData.check_status">
                  <el-form-item label="审核人">: {{ formData.check_uname || '-' }}</el-form-item>
                </el-col>
                <el-col :span="24" v-if="formData.check_status">
                  <el-form-item label="审核时间">: {{ formData.check_date || '-' }}</el-form-item>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
        </el-row>
      </el-form>
      <div slot="footer" class="flexEnd" style="width: 100%;">
        <el-button type="primary" plain @click="dialogForm = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showCheckedInfo" :close-on-click-modal="false" :destroy-on-close="true"
      custom-class="cus_dialog" width="600px" @open="dragDialog('dialogCheck')" ref="dialogCheck">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">审核记录</span>
      </div>
      <el-form :model="checkedInfo" label-width="100px">

        <el-row>
          <el-col :span="24">
            <el-form-item label="审核状态">:
              <el-tag size="mini" v-if="checkedInfo.check_status == 0 || !checkedInfo.check_status"
                type="primary">待审核</el-tag>
              <el-tag size="mini" v-if="checkedInfo.check_status == 1" type="success">审核通过</el-tag>
              <el-tag size="mini" v-if="checkedInfo.check_status == 2" type="danger">审核不通过</el-tag>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="审核说明">: {{ checkedInfo.check_desc || '-' }}</el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="操作人姓名">: {{ checkedInfo.check_name }}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="审核时间">: {{ checkedInfo.check_date }}</el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="flexEnd" style="width: 100%;">
        <el-button type="primary" plain @click="showCheckedInfo = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import citys from "../../../utils/arae_value.js";
import util from "@/utils/util";
import { exportWord } from "../../../utils/exportword";

export default {
  data() {
    return {
      searchForm: {
        login_name: "",
        firm_name: "",
        firm_num: "",
        tel: "",
        info: "",
        email_status: "",
        is_cooperation: "",
        check_status: "",
        orderBy: {},
      },
      tableData: [],
      dialogForm: false,
      formData: {},
      src:
        "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
      qyxzArr: [],
      isokArr: [],
      xzfwArr: [],
      xlyqArr: [],
      gzjyArr: [],
      wyyqArr: [],
      jsjyqArr: [],
      gwshzzArr: [],
      qyxz: {},
      isok: {},
      xzfw: {},
      xlyq: {},
      gzjy: {},
      wyyq: {},
      jsjyq: {},
      gwshzz: {},
      unitSize: {},
      page: {
        count: 0,
        current_page: 1,
        per_page: 50,
        total_page: 0
      },
      spword: "",
      CITY_DIC: {},
      showCheckedInfo: false,
      checkedInfo: {},
    };
  },
  filters: {
    handleDate(value) {	// value是输入框的内容，也是要显示的内容
      if (value) {
        return value.substring(0, 10)
      }
    }
  },
  mounted() {
    let cityList = this.TreeToArrayFor(citys)
    let CITY_DIC = {}
    for (let i = 0, len = cityList.length; i < len; i++) {

      CITY_DIC[cityList[i].value] = cityList[i].label

    }
    this.CITY_DIC = CITY_DIC
    this.getComType().then(rr => {
      this.getList();
    });
    if (this.$store.state.teacher.sys_roles) {
      this.isDelete = true
    }
  },
  computed: {
    isAdmin() {
      if (this.$store.state.teacher.sys_roles) {
        let roles = this.$store.state.teacher.sys_roles
        let arr = roles.filter(r => r.role_code.indexOf('admin') >= 0 || r.role_code == 'delrole').map(r => r.role_code)
        return arr.length > 0
      }
    },
  },
  methods: {
    getAllParentIds(data, childId) {
      const parents = this._lo.filter(data, { id: childId });
      const parentIds = this._lo.map(parents, 'parentId');
      const allParentIds = this._lo.uniq(parentIds); // 确保没有重复的id
      return allParentIds;
    },
    dragDialog(ref) {//弹框拖拽
      this.dropDialog(this.$refs[ref].$el)
    },
    getComType() {
      return new Promise(resolve => {
        this.$http
          .post("/api/sys_param_list", {
            param_type:
              "'ENTER_INFO','ISOK','SALARY_RANGE','EDUACTION','EXPERIENCE','ENGLISH_LEVEL','COMPUTER_LEVEL','GWSHZT','UNIT_SIZE'",
            used: 0
          })
          .then(res => {
            let qyxzArr = [];
            let isokArr = [];
            let xzfwArr = []; //薪资类型
            let xlyqArr = []; //学历类型
            let gzjyArr = []; //工作经验
            let wyyqArr = []; //外语要求
            let jsjyqArr = []; //计算机要求
            let gwshzzArr = []; //计算机要求
            let qyxz = {};
            let isok = {};
            let xzfw = {}; //薪资类型
            let xlyq = {}; //学历类型
            let gzjy = {}; //工作经验
            let wyyq = {}; //外语要求
            let jsjyq = {}; //计算机要求
            let gwshzz = {}; //计算机要求
            let unitSize = {}
            for (let item of res.data) {
              if (item.param_type == "ENTER_INFO") {
                qyxzArr.push(item);
                qyxz[item.param_value] = item.param_desc;
              }
              if (item.param_type == "ISOK") {
                isokArr.push(item);
                isok[item.param_value] = item.param_desc;
              }
              if (item.param_type == "SALARY_RANGE") {
                xzfwArr.push(item);
                xzfw[item.param_value] = item.param_desc;
              }
              if (item.param_type == "EDUACTION") {
                xlyqArr.push(item);
                xlyq[item.param_value] = item.param_desc;
              }
              if (item.param_type == "EXPERIENCE") {
                gzjyArr.push(item);
                gzjy[item.param_value] = item.param_desc;
              }
              if (item.param_type == "ENGLISH_LEVEL") {
                wyyqArr.push(item);
                wyyq[item.param_value] = item.param_desc;
              }
              if (item.param_type == "COMPUTER_LEVEL") {
                jsjyqArr.push(item);
                jsjyq[item.param_value] = item.param_desc;
              }
              if (item.param_type == "GWSHZT") {
                gwshzzArr.push(item);
                gwshzz[item.param_value] = item.param_desc;
              }
              if (item.param_type == "UNIT_SIZE") {
                unitSize[item.param_value] = item.param_desc;
              }
            }
            this.qyxzArr = qyxzArr;
            this.isokArr = isokArr;
            this.xzfwArr = xzfwArr;
            this.xlyqArr = xlyqArr;
            this.gzjyArr = gzjyArr;
            this.wyyqArr = wyyqArr;
            this.jsjyqArr = jsjyqArr;
            this.gwshzzArr = gwshzzArr;
            this.qyxz = qyxz;
            this.isok = isok;
            this.xzfw = xzfw;
            this.xlyq = xlyq;
            this.gzjy = gzjy;
            this.wyyq = wyyq;
            this.jsjyq = jsjyq;
            this.gwshzz = gwshzz;
            this.unitSize = unitSize
            resolve();
          });
      });
    },

    /**
     * tree转数组，循环模式，每次增加循环的变量
     * 【注意：】只能使用for 循环，不能使用es6的迭代器
     * @param tree
     * @returns {{length}|*}
     * @constructor
     */
    TreeToArrayFor(tree) {
      if (!Array.isArray(tree) || !tree.length) throw new Error("请传入数组")
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].children) {
          // 每次children有值，就把值合并到原始数据tree上去，增加原始tree，for 循环 的长度也会增加 依次处理...
          tree = tree.concat(tree[i].children)
        }
      }
      return tree
    },
    getList() {
      let data = {};
      data.login_name = this.searchForm.login_name;
      data.firm_name = this.searchForm.firm_name;
      data.firm_num = this.searchForm.firm_num;
      data.tel = this.searchForm.tel;
      data.info = this.searchForm.info;
      data.email_status = this.searchForm.email_status;
      data.is_cooperation = this.searchForm.is_cooperation;
      data.check_status = this.searchForm.check_status;
      data.qyly = 1;
      data.is_register = 1
      data.page = this.page.current_page;
      data.pagesize = this.page.per_page;
      data.orderBy = this.searchForm.orderBy
      this.$http.post("/api/enterprise_list", data).then(res => {
        res.data.data && res.data.data.map(a => {
          if (a.area_id) {
            let areaNames = util.getParentAreaByValue(citys, a.area_id)
            if (areaNames && areaNames.length > 0) {
              a.area_name_new = areaNames.map(a => a.label).reverse().join('/');
            }
            /*let aa = this.getRegionsByDistrictId(citys, a.area_id)
            if (aa && aa.province) {
              a.area_name_new = `${aa.province}/${aa.city}/${aa.district}`
            }*/
          }
        })
        this.tableData = res.data.data;
        this.page = res.data.page;
      });
    },
    getRegionsByDistrictId(regions, districtId) {
      for (let province of regions) {
        if (province.children) {
          for (let city of province.children) {
            if (city.children) {
              for (let district of city.children) {
                if (district.value === districtId) {
                  return { province: province.label, city: city.label, district: district.label };
                }
              }
            }
          }
        }
      }
      return null;
    },
    getParentId(list, iid) {
      if (list && list.length > 0) {
        for (let o of list) {
          if (o.value == iid) return o;
          const o_ = this.getParentId(o.children, iid);
          if (o_) return o_;
        }
      }
    },
    enterprisePwd(row) {
      this.$http.post("/api/reset_enter_pwd", { enter_id: row.id }).then(res => {
        this.$message({
          type: "success",
          message: "重置密码成功!新密码手机号码后六位"
        });
      });
    },
    handleModify(row) {
      this.$http.post("/api/enterprise_info", { id: row.id }).then(res => {
        if (res.data.jobList && res.data.jobList.length > 0) {
          res.data.jobList.map(a => {
            if (a.city) {
              let aa = this.getRegionsByDistrictId(citys, a.city)
              console.log(666, a.city, aa)
              if (aa && aa.province) {
                a.area_name_new = `${aa.province}/${aa.city}/${aa.district}`
              }
            }
          })
        }
        if (res.data && res.data.area_id) {
          let areaNames = util.getParentAreaByValue(citys, res.data.area_id)
          if (areaNames && areaNames.length > 0) {
            res.data.area_name = areaNames.map(a => a.label).reverse().join('/');
          }
        }
        this.formData = res.data;
        this.dialogForm = true;
      });
    },

    handlePageChange(page) {
      this.page.current_page = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.current_page = 1;
      this.page.per_page = val;
      this.getList()
    },
    submitForm(st) {
      if (st == 2 && !this.spword.trim()) {
        this.$message.error("请输入拒绝原因");
        return;
      }
      this.$confirm("确定审核?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        if (st == 2) {
          if (!this.spword) {
            this.$message.error("请输入拒绝原因");
            return;
          }
        }

        this.$http
          .post("/api/enterprise_check", {
            id: this.formData.id,
            check_status: st,
            remarks: this.spword
          })
          .then(res => {
            this.$message({
              type: "success",
              message: "审核成功!"
            });
            this.getList();
            this.dialogForm = false;
          });
      });
    },
    exportExcelDate() {
      let data = {};
      data.login_name = this.searchForm.login_name;
      data.firm_name = this.searchForm.firm_name;
      data.firm_num = this.searchForm.firm_num;
      data.tel = this.searchForm.tel;
      data.info = this.searchForm.info;
      data.email_status = this.searchForm.email_status;
      data.is_cooperation = this.searchForm.is_cooperation;
      data.check_status = this.searchForm.check_status;
      data.qyly = 1;
      data.page = this.page.current_page;
      this.$http.post("/api/export_enterprise_list", data).then(res => {
        if (res.data.url) {
          window.open(res.data.url);
        }
      });
    },
    tongbuJava() {
      this.$confirm('请确保JAVA版本已存在？是否继续同步?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let loadingInstance = this.$loading({
          lock: true,
          text: "已发送请求，请稍等...",
          spinner: 'el-icon-loading'
        })
        this.$http.post("/api/sync_zp_enter", {}).then(res => {
          loadingInstance.close()
          this.$message.success('后台同步中，请刷新查看')
          this.getList();
        }).catch(err => {
          this.$message.error('同步失败')
          loadingInstance.close()
        })

      }).catch(() => {

      });
    },
    goZhaopinqishi(id) {
      window.open("/zhaopin/#/zpqs/" + id)
    },
    handleDelete(row) {
      this.$confirm('此操作不可恢复，确定删除？', {
        type: "warning",
      }).then(() => {
        this.$http.post('/api/enterprise_delete', { id: row.id }).then(res => {
          this.$message.success('删除成功')
          this.getList()
        })
      })
    },
    sortChange({column, prop, order}) {
      let sortType = ""
      if (order === 'descending') {
        sortType = "desc"
      } else if (order === 'ascending') {
        sortType = "asc"
      }
      this.searchForm.orderBy[column.sortable] = sortType
      this.getList()
    },

    exportWord(id) {
      let jobList = []
      this.$http.post("/api/h5_enter_info", {
        check_status: 1,
        id: id
      }).then(res => {
        for (let job of res.data.jobList) {
          job.education_name = this.xlyq[job.eduaction] || '-'
          if (job.city) {
            let areaNames = util.getParentAreaByValue(citys, job.city)
            if (areaNames && areaNames.length > 0) {
              job.city_name = areaNames.map(a => a.label).reverse().join('/');
            }
          }
          jobList.push({
            job_name: job.job_name,
            need_num: job.need_num,
            education_name: job.education_name || '-',
            job_desc: job.job_desc,
            city_name: job.city_name || '-'
          })
        }
        let data = res.data
        let companyDetail = {
          firm_name: data.firm_name,
          firm_num: data.firm_num,
          enter_desc: data.enter_desc,
          jobList: jobList,
          firm_principal: data.firm_principal,
          tel: data.tel,
          email: data.email,
          address: data.address
        }
        setTimeout(() => {
          console.log(companyDetail)
          exportWord('./exportTemp/常州旅商招聘启事.docx', companyDetail, `${companyDetail.firm_name}招聘启事.docx`)
        }, 200);
      })
    },
  }
};
</script>

<style scoped lang="less">
/deep/ .el-form-item {
  margin-bottom: 0px;
}

.dialog_right {
  .lineRow {
    .cell:first-of-type {
      width: 120px;
      padding-left: 8px;
    }

  }

  .imgaddbtn {
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px dotted #ccc;
    font-size: 30px;
    cursor: pointer;
  }

  .imgremove {
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;

    cursor: pointer;
    position: absolute;
    top: -5px;
    right: -5px;
    color: #ccc;
  }

  .imgremove:hover {
    color: orange;
  }
}
</style>